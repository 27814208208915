@import '../variables';

.page-notifications {
	#new-vendor,
	#special-offers {
		margin: 1px 5px 0 0;
		height: 18px;
		width: 18px;
	}

	.display-control {
		overflow: hidden;
		height: 0;
		opacity: 0;
		transition: height 0ms 400ms, opacity 400ms 0ms;

		&.visible {
			height: auto;
			opacity: 1;
			transition: height 0ms 400ms, opacity 600ms 0ms;
		}

		.map-sub {
			width: 100%;
			height: 500px;

			&.map_single {
				height: 400px;
			}

			.delete-pin-wrapper {
				font-size: $font-size-base * 1.2;
			}
		}
	}

	.card.notifications-map {
		button.remove-pin {

		}
	}
}